import React, { useState } from "react"
import skillsData from "../Components/SkillsData"

function Skills() {
  const [skills, setSkills] = useState(skillsData)
  return (
    <div className='skills'>
      <h2>My professional Skills</h2>
      <div className='skills__grid'>
        {skills.map((item) => (
          <div key={item.id} className='skills__box'>
            <img src={item.icon} alt={item.skill} />
            <h3>{item.skill}</h3>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Skills
