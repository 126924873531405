import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./style.css"
import Nav from "./pages/Nav"
import Home from "./pages/Home"
import Portfolio from "./pages/Portfolio"
import Contact from "./pages/Contact"
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <div className='app'>
      <Analytics />
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
