import React, { useState } from "react"
import { Link } from "react-router-dom"
import { CgClose } from "react-icons/cg"
import { FaBars } from "react-icons/fa6"

function Nav() {
  const [showNav, setShowNav] = useState(true)
  return (
    <div className='nav__container'>
      <div className='nav__text'>
        <h2>
          Thodoris <br /> Kallioras
        </h2>
        <button onClick={() => setShowNav(!showNav)}>
          {showNav ? <FaBars /> : <CgClose />}
        </button>
      </div>
      <ul className={showNav ? "showNav-disable" : "showNav-active"}>
        <li>
          <Link to='/' className='link'>
            Home
          </Link>
        </li>
        <li>
          <Link to='/portfolio' className='link'>
            Portfolio
          </Link>
        </li>
        <li>
          <Link to='/Contact' className='link'>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default Nav
