import React, { useRef } from "react"
import emailjs from "@emailjs/browser"
import { BsGithub, BsLinkedin, BsInstagram, BsPinAngle } from "react-icons/bs"
import { GoNorthStar } from "react-icons/go"

function Contact() {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_7tsym4i",
        "template_xkncpy9",
        form.current,
        "9pp3L9njmoFYyfmSf"
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <footer className='contact__container'>
      <div className='contact__info'>
        <h2>
          Interested in <br /> working together? <br /> Let’s talk
        </h2>
        <h3>
          <span>
            <GoNorthStar />
          </span>
          thodoris.kallioras@gmail.com
        </h3>
      </div>

      {/* form */}
      <div className='contact__form'>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='user_name' placeholder='Full Name' />
          <input type='email' name='user_email' placeholder='Email Address' />
          <textarea
            name='message'
            placeholder='Type your message here'
            cols='30'
            rows='10'
          ></textarea>
          <button type='submit' value='Send' title='Send'>
            SUBMIT
          </button>
        </form>
      </div>
      <div className='contact__developed'>
        <h4>Developed by Theodoros Kallioras 2023</h4>
        <ul>
          <li>
            <a
              href='https://github.com/thodorisKall'
              target='_blank'
              rel='noreferrer'
            >
              <button title='Github'>
                <BsGithub />
              </button>
            </a>
          </li>
          <li>
            <a
              a
              href='https://www.linkedin.com/in/thodoris-kallioras-9a326523a/'
              target='_blank'
              rel='noreferrer'
            >
              <button title='LinkedIn'>
                <BsLinkedin />
              </button>
            </a>
          </li>
          <li>
            <button>
              <BsInstagram />
            </button>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Contact
