import React from "react"
import Header from "./Header"
import Profile from "./Profile"
import Contact from "./Contact"
import Banner from "./Banner"
import Skills from "./Skills"

function Home() {
  return (
    <main className='main__container'>
      <Header />
      <Profile />
      <Banner />
      <Skills />
      <Contact />
    </main>
  )
}

export default Home
