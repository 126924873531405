const projectsData = [
  {
    id: 1,
    name: "Calculator",
    skills: ["JavaScript", "HTML5", "CSS"],
    demo: "https://calculator-responsive-js.vercel.app/",
    code: "https://github.com/thodorisKall/calculator-responsive-js",
    img: "https://i.imgur.com/MKbcHSm.png",
  },
  {
    id: 2,
    name: "Momentum-clone",
    skills: ["React", "Typescript", "SASS"],
    demo: "https://extension-clone.vercel.app/",
    code: "https://github.com/thodorisKall/extension-clone",
    img: "https://i.imgur.com/crpxqdV.png",
  },
  {
    id: 3,
    name: "Museum",
    skills: ["HTML5", "CSS", "Tailwind"],
    demo: "https://thodoriskall.github.io/museum-gallery-tailwind-site/",
    code: "https://github.com/thodorisKall/museum-gallery-tailwind-site",
    img: "https://i.imgur.com/GiuIBx8.png",
  },
  {
    id: 4,
    name: "Portfolio",
    skills: ["JavaScript", "HTML5", "CSS"],
    demo: "https://thodoris-web-dev-portfolio.vercel.app/",
    code: "https://github.com/thodorisKall/Thodoris-WebDev-portfolio",
    img: "https://i.imgur.com/Z5fEXIL.png",
  },
  {
    id: 5,
    name: "Pomodoro",
    skills: ["React", "JavaScript", "NEXT", "Tailwind"],
    demo: "https://pomodoro-countdown-woad.vercel.app/",
    code: "https://github.com/thodorisKall/Pomodoro-countdown",
    img: "https://i.imgur.com/8Facr3H.png",
  },
  {
    id: 6,
    name: "Todo",
    skills: ["React", "JavaScript", "CSS"],
    demo: "https://todo-list-livid-mu.vercel.app/",
    code: "https://github.com/thodorisKall/Todo-List",
    img: "https://i.imgur.com/NMnsvhl.png",
  },
  {
    id: 7,
    name: "Weather",
    skills: ["React", "Typescript", "SASS"],
    demo: "https://weather-app-mu-five-84.vercel.app/",
    code: "https://github.com/thodorisKall/Weather-App",
    img: "https://i.imgur.com/1KZWJfJ.png",
  },
  {
    id: 8,
    name: "StreamingNow",
    skills: ["React", "JavaScript", "NEXT", "Tailwind"],
    demo: "https://www.streamingnow.site/",
    code: "https://github.com/thodorisKall/StreamingNow",
    img: "https://i.imgur.com/LTk30BR.png",
  },
  {
    id: 9,
    name: "BookShop",
    skills: ["React", "Node.js", "MySQL", "SASS"],
    demo: "https://i.imgur.com/05wtjqa.jpg",
    code: "https://github.com/thodorisKall/Bookshop",
    img: "https://i.imgur.com/VCLS6k3.jpg",
  },
  {
    id: 10,
    name: "Take Notes",
    skills: ["React", "Node.js", "MySQL", "SASS"],
    demo: "https://i.imgur.com/gzV58Qw.png",
    code: "https://github.com/thodorisKall/TakeNotes",
    img: "https://i.imgur.com/dcEo2Mn.png",
  },
  {
    id: 11,
    name: "Todo List",
    skills: ["React", "React Native"],
    demo: "https://i.imgur.com/BHkozQx.png",
    code: "https://github.com/thodorisKall/Todo-app-react-Native",
    img: "https://i.imgur.com/BHkozQx.png",
  },
]

export default projectsData
