import React from "react"

function Banner() {
  return (
    <div className='banner'>
      <div className='banner__project-1st'></div>
      <div className='banner__project-2nd'></div>
      <div className='banner__project-3rd'></div>
      <div className='banner__project-4th'></div>
      <div className='banner__project-5th'></div>
      <div className='banner__project-6th'></div>
    </div>
  )
}

export default Banner
